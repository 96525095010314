<template>
<div style="display: flex; flex-wrap: wrap" v-if="state.stationData.length > 0">

  <template v-for="graph in selectedGraphs">
    <template v-if="graph.type !== 'windrose'">
      <div :class="graph.displayClass" :key="graph.title">
        <highcharts :options="buildChart(graph.title, graph.metricTitle,
        () => buildChartSeries(graph, graph.type, graph.attr),
        graph.softMin, graph.softMax, graph.hardMin, graph.hardMax)"></highcharts>
      </div>

    </template>
    <template v-else>

      <div v-for="station in state.stationData" :key="graph.attr + station.station.station_id"  :class="graph.displayClass" >
        <wind-rose :title="station.station.station_name+' Wind Rose'"
                   :wind-speed-data="getWindSpeedData(station)"
                   :wind-dir-data="getWindDirData(station)"></wind-rose>
      </div>
    </template>
  </template>

</div>
</template>

<script>

import {watch, reactive, computed} from "@vue/composition-api";
import {DateTime} from "luxon";
import WindRose from "@/components/WindRose";


  let allGraphs = [
    {
      type: 'line',
      attr: "Temperature",
      title: "Outdoor Temperature",
      metricTitle: "Degrees F",
      displayClass: "lineChartWidget",
      softMin: null,
      softMax: null,
      hardMin: null,
      hardMax: null,
    },
    {
      type: 'line',
      attr: "Humidity",
      title: "Outdoor Humidity",
      metricTitle: "%",
      displayClass: "lineChartWidget",
      softMin: null,
      softMax: null,
      hardMin: 0,
      hardMax: 100,
    },
    {
      type: 'line',
      attr: "Pressure",
      title: "Barometric Pressure",
      metricTitle: "inHg",
      displayClass: "lineChartWidget",
      softMin: 29.25,
      softMax: 30.25,
      hardMin: null,
      hardMax: null,
    },
    {
      type: 'line',
      attr: "Wind Speed",
      title: "Wind Speed",
      metricTitle: "MPH",
      displayClass: "lineChartWidget",
      softMin: null,
      softMax: 20,
      hardMin: 0,
      hardMax: null,
    },
    {
      type: 'line',
      attr: "High Wind Speed",
      title: "High Wind Speed",
      metricTitle: "MPH",
      displayClass: "lineChartWidget",
      softMin: null,
      softMax: 20,
      hardMin: 0,
      hardMax: null,
    },
      {
      type: 'line',
      attr: "Wind Direction",
      title: "Wind Direction",
      metricTitle: "Degrees",
      displayClass: "lineChartWidget",
      softMin: null,
      softMax: null,
      hardMin: 0,
      hardMax: 360,
    },
    {
      attr: 'Wind Rose',
      type: 'windrose',
      displayClass: "windrose"
    },
    {
      type: 'column',
      attr: "Rain",
      title: "Rainfall",
      metricTitle: "Inches",
      displayClass: "lineChartWidget",
      softMin: null,
      softMax: .25,
      hardMin: 0,
      hardMax: null,
    },
    {
      type: 'line',
      attr: "Solar Radiation",
      title: "Solar Radiation",
      metricTitle: "W/m2",
      displayClass: "lineChartWidget",
      softMin: null,
      softMax: null,
      hardMin: 0,
      hardMax: 1500,
    },

  ]

  // PM sensor
  // Pull out series for each of 4 values
    const seriesNames = [
        'humidity',
        'pm1p0',
        'pm2p5',
        'pm10p0',
    ]
    const pmCharts = seriesNames.map((seriesName) => {

      return {
        type: 'line',
        alwaysShow: true,
        attr: "Particulate Matter",
        title: `PM ${seriesName}`,
        metricTitle: "",
        displayClass: "lineChartWidget",
        softMin: null,
        softMax: null,
        hardMin: 0,
        hardMax: null,
        dataTransform: (chartType, stationName, vals) => {
          return {
              type: chartType,
              keys: ['x', 'y'],
              name: stationName + ' ' + seriesName,
              data: vals.map((d) => {
                return [d[0], d[1].sensor_data[seriesName]]
              })
            }
        }
      }

    })

  pmCharts.forEach(c => allGraphs.push(c))

  const soilTempAttrs = [
    'Soil Temperature 2"',
    'Soil Temperature 5"',
    'Soil Temperature 10"',
    'Soil Temperature 15"',
    'EP Soil Temperature 4"',
    'EP Soil Temperature 8"',
    'EP Soil Temperature 12"',
    'EP Soil Temperature 16"',
  ];
  soilTempAttrs.forEach((attr) => {
    allGraphs.push({
      type: 'line',
      attr: attr,
      title: attr,
      metricTitle: "Degrees F",
      displayClass: "quarterLineChartWidget",
      softMin: 40,
      softMax: 75,
      hardMin: null,
      hardMax: null,
    });
  });
  const soilMoistAttrs = [
    'Soil Moisture 2"',
    'Soil Moisture 5"',
    'Soil Moisture 10"',
    'Soil Moisture 15"',
    'EP Soil Moisture 4"',
    'EP Soil Moisture 8"',
    'EP Soil Moisture 12"',
    'EP Soil Moisture 16"',
  ];
  soilMoistAttrs.forEach((attr) => {
    allGraphs.push({
      type: 'line',
      attr: attr,
      title: attr,
      metricTitle: "mBar",
      displayClass: "quarterLineChartWidget",
      softMin: null,
      softMax: null,
      hardMin: 0,
      hardMax: (attr.startsWith('EP') ? 100 : 200),
    });
  });


  const attributeMap = {
    whinwan: {
      Temperature: "avg_temperature",
      Humidity: "humidity",
      Pressure:  "barometer_sea_level",
      Rain: "rainfall_inches",
      "Wind Speed": "avg_wind_speed",
      "High Wind Speed": "high_wind_speed",
      "Wind Direction": "wind_dir",
      "Solar Radiation": "solar_radiation",
      "Particulate Matter": "moteino_data",
      'Soil Temperature 2"': 'soil_temp_1',
      'Soil Temperature 5"': 'soil_temp_2',
      'Soil Temperature 10"': 'soil_temp_3',
      'Soil Temperature 15"': 'soil_temp_4',
      'Soil Moisture 2"': 'soil_moist_1',
      'Soil Moisture 5"': 'soil_moist_2',
      'Soil Moisture 10"': 'soil_moist_3',
      'Soil Moisture 15"': 'soil_moist_4',
      'EP Soil Moisture 4"': null,
      'EP Soil Moisture 8"': null,
      'EP Soil Moisture 12"': null,
      'EP Soil Moisture 16"': null,
      'EP Soil Temperature 4"': null,
      'EP Soil Temperature 8"': null,
      'EP Soil Temperature 12"': null,
      'EP Soil Temperature 16"': null,

    },
    solchip: {
      Temperature: "air_temperature5_f",
      Humidity: "air_humidity6",
      Pressure: "barometric_pressure3",
      Rain: "rain_diff_inches",
      "Wind Speed": "wind_speed1",
      "High Wind Speed": "digital1_max",
      "Wind Direction": "wind_direction1",
      "Solar Radiation": "solar_radiation2",
      'Soil Temperature 2"': 'soil_temperature_2_inches',
      'Soil Temperature 5"': 'soil_temperature_5_inches',
      'Soil Temperature 10"': 'soil_temperature_10_inches',
      'Soil Temperature 15"': 'soil_temperature_15_inches',
      'Soil Moisture 2"': 'soil_moisture_2_inches',
      'Soil Moisture 5"': 'soil_moisture_5_inches',
      'Soil Moisture 10"': 'soil_moisture_10_inches',
      'Soil Moisture 15"': 'soil_moisture_15_inches',
      'EP Soil Moisture 4"': "soil_moisture_ep_4_inches",
      'EP Soil Moisture 8"': "soil_moisture_ep_8_inches",
      'EP Soil Moisture 12"': "soil_moisture_ep_12_inches",
      'EP Soil Moisture 16"': "soil_moisture_ep_16_inches",
      'EP Soil Temperature 4"': "soil_temperature_ep_4_inches",
      'EP Soil Temperature 8"': "soil_temperature_ep_8_inches",
      'EP Soil Temperature 12"': "soil_temperature_ep_12_inches",
      'EP Soil Temperature 16"': "soil_temperature_ep_16_inches",

    },
    davis: {
      Temperature: "temp_f",
      Humidity: "humidity_percent",
      Pressure: "pressure_in_hg",
      Rain: "rain_inches",
      "Wind Speed": "wind_speed_mph",
      "High Wind Speed": "wind_speed_high_mph",
      "Wind Direction": "wind_direction_degrees",
      "Solar Radiation": "solar_rad",
      'Soil Temperature 2"': 'soil_temp_1_temp_avg',
      'Soil Temperature 5"': 'soil_temp_2_temp_avg',
      'Soil Temperature 10"': 'soil_temp_3_temp_avg',
      'Soil Temperature 15"': 'soil_temp_4_temp_avg',
      'Soil Moisture 2"': 'soil_moist_1_moist_soil_last',
      'Soil Moisture 5"': 'soil_moist_2_moist_soil_last',
      'Soil Moisture 10"': 'soil_moist_3_moist_soil_last',
      'Soil Moisture 15"': 'soil_moist_4_moist_soil_last',
      'EP Soil Moisture 4"': "ep_moist_soil_last_1",
      'EP Soil Moisture 8"':"ep_moist_soil_last_2",
      'EP Soil Moisture 12"': "ep_moist_soil_last_3",
      'EP Soil Moisture 16"': "ep_moist_soil_last_4",
      'EP Soil Temperature 4"': "ep_temp_last_1",
      'EP Soil Temperature 8"':  "ep_temp_last_2",
      'EP Soil Temperature 12"': "ep_temp_last_3",
      'EP Soil Temperature 16"': "ep_temp_last_4",
    }
}

export default {
  name: "StationDailyGraph",
  components: {
    WindRose
  },
  props: {
    stationData: {type: Array},
    attributes: {type: Array}
  },
  setup(props) {

    const state = reactive({
      stationData: [...props.stationData],
      attributes: [...props.attributes],
    });

    function buildChartSeries(graph, chartType, attrName) {
      return state.stationData.map(sd => {
        let station_attr_name = attributeMap[sd.station.station_type][attrName];

        if (station_attr_name === null || station_attr_name === undefined) {
          return [];
        }
        if (sd.data[station_attr_name] === undefined) {
          // Currently VC davis has these alternate keys
          const altAttrLookup = {
            soil_temp_1_temp_avg: "iss_soil_temp_1",
            soil_temp_2_temp_avg: "iss_soil_temp_2",
            soil_temp_3_temp_avg: "iss_soil_temp_3",
            soil_temp_4_temp_avg: "iss_soil_temp_4",
            soil_moist_1_moist_soil_last: "iss_soil_moist_1",
            soil_moist_2_moist_soil_last: "iss_soil_moist_2",
            soil_moist_3_moist_soil_last: "iss_soil_moist_3",
            soil_moist_4_moist_soil_last: "iss_soil_moist_4",

          }
          station_attr_name = altAttrLookup[station_attr_name];
        }

        if (sd.data[station_attr_name] === undefined) {
          return [];
        }

        let vals = [...sd.data[station_attr_name]];

        // If solchip, sort by the datetime
        if (sd.station.station_type === 'solchip' || sd.station.station_type === 'whinwan') {
          vals.sort((a, b) => a[0] - b[0])
        }

        // const seriesData = vals.map(o => {
        //     return [o[0], o[1]]
        // });

        // If data is complex or multi-part, call into a function to get the data
        if (graph.dataTransform !== undefined) {
          return graph.dataTransform(chartType, sd.station.station_name ? sd.station.station_name: sd.station.station_id, vals)

        }

        return {
          type: chartType,
          keys: ['x', 'y'],
          name: sd.station.station_name ? sd.station.station_name: sd.station.station_id,
          data: vals,
        }
      });
    }

    watch(() => props.stationData, async(newStationData) => {

      state.stationData = [...newStationData];
    })
    watch(() => props.attributes, () => {
      state.attributes = [...props.attributes];
    })

    function formatTS(ts) {
      return DateTime.fromSeconds(parseInt(ts)).toLocaleString(DateTime.DATETIME_MED)
    }

    function lookupStationAttribute(station, attr, values) {
      return values[attributeMap[station.station_type][attr]];
    }

    function convertCToF(val) {
      return val * 9/5 + 32;
    }

    function buildChart(title, yLabel, getSeries, softMin, softMax, hardMin, hardMax) {

      return {
        title: {
          text: title
        },
        pane: {
            size: '85%'
        },
        yAxis: {
            title: yLabel,
            softMin: softMin,
            softMax: softMax,
            min: hardMin,
            max: hardMax
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%l:%M%P',
            hour: '%l:%M%P',
            day: '%e. %b',
            week: '%e. %b',
            month: '%b \'%y',
            year: '%Y'
          }
        },
        series: getSeries(),
        credits: {
            enabled: false
          },
        legend: {
            // layout: 'vertical',
            // align: 'right',
            // verticalAlign: 'middle'
        },
        tooltip: {
          //dateTimeLabelFormats: {minute: "%l:%M%P"}
          dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: "%b %e %l:%M%P",
            hour: '%l:%M%P',
            day: '%e. %b',
            week: '%e. %b',
            month: '%b \'%y',
            year: '%Y'
          }
        },
        responsive: {
          // rules: [{
          //     condition: {
          //         maxWidth: 1200
          //     },
          //     chartOptions: {
          //         legend: {
          //             layout: 'horizontal',
          //             align: 'center',
          //             verticalAlign: 'bottom'
          //         }
          //     }
          // }]
      },
      time: {
        useUTC: false
      }
      }
    }

    function getWindSpeedData(stationData) {
      const windSpeedAttr = attributeMap[stationData.station.station_type]['Wind Speed'];
      const rawSpeedData = stationData.data[windSpeedAttr];
      if (rawSpeedData === undefined) {
        return [];
      }
      return rawSpeedData
    }
    function getWindDirData(stationData) {
      const windDirAttr = attributeMap[stationData.station.station_type]['Wind Direction'];
      const rawDirData = stationData.data[windDirAttr];
      if (rawDirData === undefined) {
        return {};
      }
      return rawDirData
    }



    return {
      state,
      formatTS,
      lookupStationAttribute,

      buildChart,
      buildChartSeries,
      allGraphs,
      convertCToF,
      selectedGraphs: computed(() => {
        return allGraphs.filter((g) => {return g.alwaysShow || state.attributes.includes(g.attr)});
      }),
      //buildWindRoseChart,
      getWindSpeedData,
      getWindDirData,
    }
  }
}


const dirStrs = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW']
const lookup = {};
dirStrs.forEach((d, i) => {
  lookup[d] = 22.5 * i
});


</script>


<style scoped>
  table {
    font-size: 9pt
  }
  td {
    max-width: 180px;
    overflow: auto;
  }

  .lineChartWidget {
    flex: 0 0 24%;
  }
  .quarterLineChartWidget {
    flex: 0 0 24%;
  }
  .windrose {
    flex: 0 0 24%;
  }



  @media (max-width: 1000px) {
    .lineChartWidget {
      flex: 0 0 100%;
    }
    .quarterLineChartWidget {
      flex: 0 0 50%;
    }
    .windrose {
    flex: 0 0 50%;
  }
  }
</style>