<template>
  <div>
     <h3>Daily conditions </h3>

    <div class="mainLayout">
      <div class="filters">

        <div style="display: flex; flex-direction: column;">
          <div class="dateFilter">
            <!-- date select -->
            <label>Date:</label>
            <input type="date" :value="state.selectedDate ? state.selectedDate.toFormat('yyyy-MM-dd'): null"
                   @input="event => state.selectedDate = parseDate(event.target.value)" />


          </div>
          <div style="flex: 0 0 auto; margin: 1em;">
            <!-- station select -->
            <div v-if="comparisonStations.length > 0" style="height: 200px; overflow-y: auto; border: 1px solid;">
              <div v-for="station in comparisonStations" :key="station.station_type+' '+station.station_id">
                <label><input type="checkbox" :checked="isStationSelected(station)" @change="() => toggleStationSelected(station)"/> {{station.station_type}} - {{formatStationName(station)}}</label>
              </div>
            </div>
          </div>
          <div class="selectFilter">
            <!-- attribute select -->
            <div v-for="attr in mappedAttributes" :key="attr">
                <label><input type="checkbox" :checked="isAttributeSelected(attr)" @change="() => toggleAttributeSelected(attr)"/> {{attr}}</label>
              </div>

          </div>
        </div>

      </div>
      <div class="content">
        <div class="loading"><span v-show="isLoading"><font-awesome-icon icon="fa-solid fa-spinner" spin-pulse /> Loading ...</span></div>
        <div v-if="state.selectedStations.length > 0 && state.selectedDate !== ''">
          <station-daily-graph :stationData="state.stationData" :attributes="state.selectedAttributes"></station-daily-graph>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
import {DateTime} from 'luxon'
import {computed, onMounted, reactive, watch} from '@vue/composition-api'
import StationDailyTable from "@/components/StationDailyTable";
import StationDailyGraph from "@/components/StationDailyGraph";

async function fetchStations(dateStr) {
  const raw_url = `https://iot-data.whin.org/weather/daily-conditions/stations?date=${dateStr}`;
  //const cached_url = `https://d55l7rpngv2m6.cloudfront.net/weather/daily-conditions/stations?date=${dateStr}`;
  const resp = await fetch(raw_url);
  return resp.json();
}

const _stationDataCache = new Map();
const _getCacheKey = (station, dateStr) => station.station_id + dateStr;
function hasCachedStationDataForDate(station, dateStr) {
  return _stationDataCache.has(_getCacheKey(station, dateStr));
}
function getCachedStationDataForDate(station, dateStr) {
  return _stationDataCache.get(_getCacheKey(station, dateStr));
}
function setCachedStationDataForDate(station, dateStr, data) {
  _stationDataCache.set(_getCacheKey(station, dateStr), data);
}


async function fetchStationData(station, dateStr) {
  if (hasCachedStationDataForDate(station, dateStr)) {
    return getCachedStationDataForDate(station, dateStr);
  }

  //const raw_api_endpoint = `https://1p4dpbsujk.execute-api.us-east-1.amazonaws.com/weather/daily-conditions?date=${dateStr}&station_type=${station.station_type}&station_id=${station.station_id}&format=json`;
  const cached_api_endpoint = `https://d55l7rpngv2m6.cloudfront.net/weather/daily-conditions?date=${dateStr}&station_type=${station.station_type}&station_id=${station.station_id}&format=json`;
  const resp = await fetch(cached_api_endpoint);
  const data = resp.json();

  setCachedStationDataForDate(station, dateStr, data);

  return data;
}

const mappedAttributes = [
    'Temperature',
    'Humidity',
    'Pressure',
    'Wind Speed',
    'High Wind Speed',
    'Wind Direction',
    'Wind Rose',
    'Rain',
    'Solar Radiation',
    'Particulate Matter',
    'Soil Temperature 2"',
    'Soil Temperature 5"',
    'Soil Temperature 10"',
    'Soil Temperature 15"',
    'Soil Moisture 2"',
    'Soil Moisture 5"',
    'Soil Moisture 10"',
    'Soil Moisture 15"',
    'EP Soil Moisture 4"',
    'EP Soil Moisture 8"',
    'EP Soil Moisture 12"',
    'EP Soil Moisture 16"',
    'EP Soil Temperature 4"',
    'EP Soil Temperature 8"',
    'EP Soil Temperature 12"',
    'EP Soil Temperature 16"',

]

/* eslint-disable vue/no-unused-components */
export default {
  name: "DailyConditions",
  components: {
    StationDailyTable,
    StationDailyGraph
  },
  setup() {

    const state = reactive({
      isFetchingStations: false,
      isFetchingData: false,
      isChangingDate: false,
      isTogglingData: false,

      selectedDate: null,
      stations: [],
      selectedStations: [],
      stationData: [],
      selectedAttributes: [...mappedAttributes],
    })

    watch(() => state.selectedDate, async (newDate) => {
      state.isChangingDate = true;
      if (state.stations.length === 0) {
        const stations = await fetchStations(newDate.toFormat('yyyy-MM-dd'));
        stations.sort((a, b) => {
          if (a.station_type !== b.station_type) {
            return a.station_type.localeCompare(b.station_type);
          } else {
            return formatStationName(a).localeCompare(formatStationName(b))
          }
        })
        state.stations = stations;

        // // Update selected stations
        // const currentSelectedStations = state.selectedStations.filter(selected => {
        //   return state.stations.map(s => s.station_id).includes(selected.station_id)
        // })
        // const currentSelectedIds = currentSelectedStations.map(s => s.station_id);
        // const newSelectedStations = [];
        // state.stations.forEach((s) => {
        //   if (currentSelectedIds.includes(s.station_id)) {
        //     newSelectedStations.push(s);
        //   }
        // });
        //
        //
        // state.selectedStations = newSelectedStations;
      }


      await updateStationData();

      state.isChangingDate = false;
    })

    async function updateStationData() {
       const stationData = await Promise.all(state.selectedStations.map((station) => {
          return fetchStationData(station, state.selectedDate.toFormat('yyyy-MM-dd'))
      }))

      let newStationsWithData = [];
      state.selectedStations.forEach((station, i) => {
        newStationsWithData.push({
          station: station,
          data: stationData[i]
        })
      })
      state.stationData = newStationsWithData;
    }

    function isStationSelected(station) {
       return state.selectedStations.includes(station);
    }

    function isAttributeSelected(attr) {
      return state.selectedAttributes.includes(attr)
    }

    async function toggleStationSelected(station) {
       state.isTogglingData = true;

       if (isStationSelected(station)) {
         state.selectedStations.splice(state.selectedStations.indexOf(station), 1);
       } else {
         state.selectedStations.push(station);
       }


       await updateStationData();
       state.isTogglingData = false;
    }

    function toggleAttributeSelected(attr) {
      if (isAttributeSelected(attr)) {
        state.selectedAttributes.splice(state.selectedAttributes.indexOf(attr), 1);
      } else {
        state.selectedAttributes.push(attr);
      }
    }

    function formatStationName(station) {
       return station.station_name ? station.station_name : station.station_id;
    }

    onMounted(() => {
      state.selectedDate = DateTime.now();
    })

    function isSolchipPage() {
      return window.location.href.endsWith("/solchip/index.html")
    }

    return {
      state,
      comparisonStations: computed(() => {
        if (isSolchipPage()) {
          return state.stations.filter((s) => {
            return ([
                "WHIN013E-TIPP002",
                "SolChip V2 ASREC",
                "WHIN022E-CLIN003 Clinton Prairie",
                "SolChip V2 Clinton Prairie",
                //"WW4 - Ivy Tech",
                "004",
                "SolChip V2 Ivy Tech",
                "WHIN082E-WHIT009 AeroSite",
                "SolChip V2 Reynolds Site",
                "WHIN051E-BENT005",
            ].includes(s.station_name)) || (s.station_name.toLowerCase().startsWith("solchip"))
          })
        }
        return state.stations;

      }),
      parseDate: (v) => DateTime.fromISO(v),
      isStationSelected,
      toggleStationSelected,
      formatStationName,
      mappedAttributes,
      isAttributeSelected,
      toggleAttributeSelected,
      isLoading: computed(() => {
        return state.isFetchingStations || state.isFetchingData || state.isChangingDate || state.isTogglingData;
      })
    }
  }

}
</script>

<style scoped>
.loading {
  min-height: 35px;
  width: 100%;

  text-align: left;
  margin: 0 1em;
  font-weight: normal;
  font-size: 23px;
}
  .mainLayout {
    display: flex;
  }
  .filters {
    flex: 0 1 25%;
    margin: 1em;
    border-right: 1px solid #888888;
  }
  .content {
    flex: 0 0 75%;
  }

  .dateFilter {
    flex: 0 0 auto; margin: 1em;
  }
  .dateFilter label {
    width: 100%;
  }
  .dateFilter input {
    width: 100%;
  }

  .selectFilter {
    margin: 1em;
  }
  .selectFilter select {
    width: 100%;
    min-height: 200px;
  }

  @media (max-width: 1000px) {
    .mainLayout {
      flex-direction: column;
    }
    .filters {
      flex: 0 1 100%;
      border: none;
    }

  }

</style>