import Vue from 'vue'
import App from './App.vue'

import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)
import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue);

import exportingInit from 'highcharts/modules/exporting'
import exportingOfflineInit from 'highcharts/modules/offline-exporting'
import exportingDataInit from 'highcharts/modules/export-data'
import initHighchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
exportingInit(Highcharts)
exportingDataInit(Highcharts)
exportingOfflineInit(Highcharts)
initHighchartsMore(Highcharts)

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
library.add(faSpinner);
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
