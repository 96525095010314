<template>
  <div id="app">

    <DailyConditions />
  </div>
</template>

<script>
import DailyConditions from './pages/DailyConditions.vue'

export default {
  name: 'App',
  components: {
    DailyConditions
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
  margin-top: 1em;
}
</style>
