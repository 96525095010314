<template>
<div>
  <table>
    <thead>
      <tr>
        <th>Timestamp</th>
        <th v-for="attr in mappedAttributes" :key="attr">
          {{attr}}
        </th>
      </tr>
    </thead>
    <tr v-for="row in state.data" :key="row.ts">
      <td>{{formatTS(row.ts)}}</td>
      <td v-for="attr in mappedAttributes" :key="attr">
        {{ lookupStationAttribute(station, attr, row) }}
      </td>
    </tr>
  </table>
</div>
</template>

<script>

import {watch, reactive, onMounted} from "@vue/composition-api";
import {DateTime} from "luxon";

  async function fetchStationData(station, dateStr) {
     const resp = await fetch(`https://d55l7rpngv2m6.cloudfront.net/weather/daily-conditions?date=${dateStr}&station_type=${station.station_type}&station_id=${station.station_id}&format=table`);
     return resp.json();
  }

  const mappedAttributes = [
      'Temperature',
      'Humidity',
      'Pressure',
      'Wind Speed'
  ]


  const attributeMap = {
    whinwan: {
      Temperature: "avg_temperature",
      Humidity: "humidity",
      Pressure: "barometer",
      Rain: "rainfall",
      "Wind Speed": "avg_wind_speed",
    },
    solchip: {
      Temperature: "air_temperature5",
      Humidity: "air_humidity6",
      Pressure: "barometric_pressure3",
      Rain: "rain_guage2",
      "Wind Speed": "wind_speed1"
    },
    davis: {
      Temperature: "iss_temp_f",
      Humidity: "iss_humidity_percent",
      Pressure: "iss_pressure_in_hg",
      Rain: "iss_rain_inches",
      "Wind Speed": "iss_wind_speed_mph",
    }
}

export default {
  name: "StationDailyTable",
  props: {
    station: {type: Object},
    date: {type: String}
  },
  setup(props) {


    const state = reactive({
      data: [],
      attrs: []
    });

    function updateData(data) {
      state.data = [];
      state.attrs = [];

      const attrs = new Set([]);
      Object.entries(data).forEach((item) => {
        const ts = item[0];
        const vals = item[1];
        Object.keys(vals).forEach(k => attrs.add(k));
        vals.ts = ts;
        state.data.push(vals);
      })
      // Update attributes
      attrs.forEach(attr => state.attrs.push(attr));

    }

    onMounted(async () => {
      updateData(await fetchStationData(props.station, props.date));
    })



    watch(() => props.date, async(newDate) => {
      console.log(`Date changed to ${newDate}`)
      updateData(await fetchStationData(props.station, newDate))
    })

    function formatTS(ts) {
      return DateTime.fromSeconds(parseInt(ts)).toLocaleString(DateTime.DATETIME_MED)
    }

    function lookupStationAttribute(station, attr, values) {
      return values[attributeMap[station.station_type][attr]];
    }

    return {
      state,
      formatTS,
      lookupStationAttribute,
      mappedAttributes,
    }
  }
}
</script>

<style scoped>
  table {
    font-size: 9pt
  }
  td {
    max-width: 180px;
    overflow: auto;
  }
</style>