<template>
        <highcharts :options="options"></highcharts>
</template>

<script>
export default {
  name: "WindRose",
  props: {
    title: {type: String},
    windSpeedData: {type: Array},
    windDirData: {type: Array},
  },
  setup(props) {

    function convertDegreesToStr(dir) {
      /*
      From Davis discord channel, this is the mapping they use:
      Note, 360 is north, 0 is no wind...
      N (0, 11.25] and (348.75, 360]
      NNE (11.25, 33.75]
      NE (33.75, 56.25]
      ENE (56.25, 78.75]
      E (78.75, 101.25]
      ESE (101.25, 123.75]
      SE (123.75, 146.25]
      SSE (146.25, 168.75]
      S (168.75, 191.25]
      SSW (191.25, 213.75]
      SW (213.75, 236.25]
      WSW (236.25, 258.75]
      W (258.75, 281.25]
      WNW (281.25, 303.75]
      NW (303.75, 326.25]
      NNW (326.25, 348.75]
      */
      if (dir === 0) {
        return 'N';
      }
      let directionCat;
      if ((dir > 0 && dir <= 11.25) || (dir > (360-11.25))) {
        directionCat = 'N'
      } else if ( dir <= 33.75) {
        directionCat = 'NNE'
      } else if (dir <= 56.25) {
        directionCat = 'NE'
      } else if (dir <= 78.75) {
        directionCat = 'ENE'
      } else if (dir <= 101.25) {
        directionCat = 'E'
      } else if (dir <= 123.75) {
        directionCat = 'ESE'
      } else if (dir <= 146.25) {
        directionCat = 'SE'
      } else if (dir <= 168.75) {
        directionCat = 'SSE'
      } else if (dir <= 191.25) {
        directionCat = 'S'
      } else if (dir <= 213.75) {
        directionCat = 'SSW'
      } else if (dir <= 236.25) {
        directionCat = 'SW'
      } else if (dir <= 258.75) {
        directionCat = 'WSW'
      } else if (dir <= 281.25) {
        directionCat = 'W'
      } else if (dir <= 303.75) {
        directionCat = 'WNW'
      } else if (dir <= 326.25) {
        directionCat = 'NW'
      } else {
        directionCat = 'NNW'
      }
      return directionCat;
    }

    function buildWindRoseChart(rawSpeedData, rawDirData) {

      if (rawSpeedData === undefined || rawDirData === undefined) {
        return {title: {text: props.title}, series: [], credits: {enabled: false}};
      }

      const speedCategories = [
        '0-2 MPH',
        '2-4 MPH',
        '4-6 MPH',
        '6-8 MPH',
        '8-10 MPH',
        '10-20 MPH',
        '>20 MPH',
      ];
      const directionCategories = [
          'N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW',
      ]
      const buckets = new Map();
      directionCategories.forEach(dir => {
        buckets.set(dir, new Map());
        speedCategories.forEach(cat => {
          buckets.get(dir).set(cat, 0)
        })
      })

      rawSpeedData.forEach((sp, i) => {
        let speed = sp[1];
        if (rawDirData === undefined || i >= rawDirData.length) {
          return;
        }
        let dir = rawDirData[i][1];

        let speedCat;
        let directionCat;

        if (speed < 2) {
          speedCat = '0-2 MPH'
        } else if (speed < 4) {
          speedCat = '2-4 MPH'
        } else if (speed < 6) {
          speedCat = '4-6 MPH'
        } else if (speed < 8) {
          speedCat = '6-8 MPH'
        } else if (speed < 10) {
          speedCat = '8-10 MPH'
        } else if (speed < 20) {
          speedCat = '10-20 MPH'
        } else  {
          speedCat = '>20 MPH'
        }

        directionCat = convertDegreesToStr(dir)


        // Increment bucket
        const currentVal = buckets.get(directionCat).get(speedCat)
        buckets.get(directionCat).set(speedCat, currentVal + 1);
      })

      // Compute table
      const totalCount = rawSpeedData.length;
      let data = []

      speedCategories.forEach((speedCat) => {
        const series = {name: speedCat, data: []};
        directionCategories.forEach((dirCat) => {
          const percentage = buckets.get(dirCat).get(speedCat) / totalCount * 100.0
          series.data.push(parseFloat(percentage.toFixed(1)));
        })
        data.push(series);
      })

      return {
        chart: {
          polar: true,
          type: 'column'
        },
        series: data,
        title: {text: props.title},
        pane: {
            size: '80%'
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          y: 20,
          layout: 'vertical'
        },
        xAxis: {
          tickmarkPlacement: 'on',
          categories: directionCategories,
        },
        yAxis: {
          min: 0,
          endOnTick: false,
          showLastLabel: true,
          title: {
            text: 'Frequency (%)'
          },
          labels: {
            formatter: function () {
              return this.value.toFixed(1) + '%';
            }
          },
          reversedStacks: false
        },
        tooltip: {
            valueSuffix: '%'
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            shadow: false,
            groupPadding: 0,
            pointPlacement: 'on'
          }
       },
      credits: {
          enabled: false
        },
      }
    }

    return {
      options: buildWindRoseChart(props.windSpeedData, props.windDirData)
    }

  }
}
</script>

<style scoped>

</style>